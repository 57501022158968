import type { Scene } from "../Scene"
import type { Lightshow } from "../Lightshow"
import { Snapshot } from "../Snapshot"
import { DataHandlerSnapshot } from "../../datahandlers/DataHandlerSnapshot"
import { DataHandlerDevice } from "../../datahandlers/DataHandlerDevice"

import {
	Device,
	type BaseDeviceRawData,
	type BaseEidosType,
	type DeviceStatusAppearance,
	StatusColors,
	DeviceRPi,
} from "."
import { LuxedoRPC } from "luxedo-rpc"

export type DeviceGroupSlotOverlapOverride = {
	id?: number
	main_slot: number
	other_slot: number
	gamma: number
	rel_brightness: number
	custom?: boolean
}

export type DeviceGroupSlot = {
	id: number
	group_id: number
	device_id: number | null
	pos_x: number
	pos_y: number
	scale_x: number
	scale_y: number
	height: number
	width: number
	priority: number
	gamma?: number
	deleted?: boolean
	overlap_override?: Array<DeviceGroupSlotOverlapOverride>
}

export type DeviceGroupOverlap = {
	id: number
	main_slot: number
	other_slot: number
	gamma: number
	rel_brightness: number
}

type StatusDeviceGroup = "EMPTY" | "OFFLINE" | "ONLINE" | "PARTIAL"

interface EidosDeviceGroup extends BaseEidosType {
	status: StatusDeviceGroup
}

export interface DeviceGroupRawData extends BaseDeviceRawData {
	status: StatusDeviceGroup
	type_id: "dev_amalgam" | "dev_group"
	slots: Array<DeviceGroupSlot>
	projector_dims: [number, number] | [null, null]
	eidos: EidosDeviceGroup
	blending: boolean
}

export interface DeviceGroup extends Device<DeviceGroupRawData> {
	_eidos: EidosDeviceGroup
	_status: StatusDeviceGroup
	typeId: "dev_amalgam" | "dev_group"
	slots: Array<DeviceGroupSlot>
	blending: boolean
}

export class DeviceGroup extends Device<DeviceGroupRawData> {
	constructor(data: DeviceGroupRawData) {
		super(data)
	}

	protected importResolution(data: DeviceGroupRawData) {
		return
	}

	protected importData(data: DeviceGroupRawData): void {
		super.importData(data)
		this.slots = data.slots.map((slot) => ({
			...slot,
			overlap_override: slot.overlap_override ?? [],
		}))
		this.blending = data.blending
	}

	protected exportData(): Partial<DeviceGroupRawData> {
		return {
			name: this.name,
			ui_color: this._color,
			slots: this.slots,
			blending: this.blending,
		}
	}

	protected statusAppearanceMap: Record<StatusDeviceGroup, DeviceStatusAppearance> = {
		OFFLINE: { text: "Offline", color: StatusColors.off },
		ONLINE: { text: "Online", color: StatusColors.idle },
		PARTIAL: { text: "Partially Online", color: StatusColors.initializing },
		EMPTY: { text: "Empty", color: StatusColors.greyOut },
	}

	// #region ================= Utility =================

	get resX(): number {
		let width = 0
		for (const slot of this.slots) {
			if (slot.deleted) continue
			const w = slot.pos_x + slot.width * slot.scale_x
			if (w > width) width = w
		}
		// Round up to nearest multiple of 2
		if (width % 2) width += 2 - (width % 2)
		return width
	}

	get resY(): number {
		let height = 0
		for (const slot of this.slots) {
			if (slot.deleted) continue
			const h = slot.pos_y + slot.height * slot.scale_y
			if (h > height) height = h
		}
		// Round up to nearest multiple of 2
		if (height % 2) height += 2 - (height % 2)
		return height
	}

	public getChildDevices() {
		const devices: DeviceRPi[] = []
		for (const { device_id: deviceId } of this.children) {
			if (!deviceId) continue
			const dev = DataHandlerDevice.get(deviceId) as DeviceRPi
			if (dev) devices.push(dev)
		}

		return devices
	}

	// #endregion ============== Utility =================
	// #region =================  DevOps =================

	public async previewShow(show: Scene | Lightshow, repeatCount = 1, disableSync = false) {
		const onlineChildren = this.getChildDevices().filter((device) => device.isOnline)

		if (onlineChildren.length === 0) return

		super.previewShow(show, repeatCount, disableSync)

		const promises: Promise<void>[] = []
		for (const child of onlineChildren) {
			promises.push(
				child.listenEidosCondition(
					(eidos) => eidos.proj_id === show.id || eidos.player?.id === show.id,
					30
				)
			)
		}
		await Promise.race(promises)

		// await this.platoCall("play_scene", [show.id, repeatCount, disableSync])
	}

	async clearPreview() {
		const onlineChildren = this.getChildDevices().filter((device) => device.isOnline)
		if (onlineChildren.length === 0) return

		const promises: Promise<void>[] = []
		for (const child of onlineChildren) {
			promises.push(child.clearPreview())
		}
		await Promise.race(promises)
	}

	async activateSpotlight(): Promise<void> {
		return this.showGrid(false, 200, false)
	}

	async showGrid(complex?: boolean, gridSize?: number, blending?: boolean) {
		return await LuxedoRPC.api.device.device_group_arrangement_preview(
			this.id!,
			{
				complex: complex ?? false,
				grid_size: gridSize ?? 200,
				blending: blending ?? false,
			},
			this.slots
		)
	}

	async clearGrid() {
		return await LuxedoRPC.api.device.device_group_stop_preview(this.id!)
	}

	// #endregion ========================================

	// #region ================= Getters =================

	get children() {
		return this._rawData.slots
	}

	get isOnline() {
		const status = this._status
		return status == "ONLINE" || status == "PARTIAL"
	}

	get isReady() {
		return true
	}

	get isCalibrated() {
		return true
	}

	public async getSnapshot() {
		const w = this.resX
		const h = this.resY

		const canvas = document.createElement("canvas")

		canvas.width = w
		canvas.height = h

		const context = canvas.getContext("2d")
		if (!context) return
		context.fillStyle = "#000"
		context?.fillRect(0, 0, w, h)

		const prioritized = this._rawData.slots?.sort((s1, s2) => {
			if (s1.priority != s2.priority) return s1.priority - s2.priority

			return s1.id - s2.id
		})

		if (!prioritized) return

		for (const slot of prioritized) {
			const attachedProjector = DataHandlerDevice.get(slot.device_id!)
			if (attachedProjector?.defaultSnap) {
				const snapshot = DataHandlerSnapshot.get(attachedProjector.defaultSnap)
				let img
				try {
					img = await snapshot.asyncImageLoad()
				} catch (e) {
					console.error("Unable to load child image", { error: e, child: attachedProjector })
					img = new Image(slot.width, slot.height)
				}
				context?.drawImage(
					img,
					slot.pos_x,
					slot.pos_y,
					slot.width * slot.scale_x,
					slot.height * slot.scale_y
				)
			} else {
				context.fillStyle = "#222"
				context?.fillRect(
					slot.pos_x,
					slot.pos_y,
					slot.width * slot.scale_x,
					slot.height * slot.scale_y
				)
				context.strokeStyle = "#444"
				context.lineWidth = 4
				context?.strokeRect(
					slot.pos_x,
					slot.pos_y,
					slot.width * slot.scale_x,
					slot.height * slot.scale_y
				)
			}
		}

		const tempSnap = new Snapshot({
			src: canvas.toDataURL("image/jpeg", 1.0),
			cal_id: -1,
			datesaved: new Date().toString(),
			dev_id: this.id!,
			is_default: true,
			resolution: {
				w,
				h,
			},
			id: -1,
		})

		return tempSnap
	}

	get sceneCache() {
		return null
	}

	/**
	 * Sends a projector power signal to the backend, resolves when the new projector state reflects the provided status.
	 * @param newPower The intended power signal of the internal projector
	 */
	async setProjectorPower(newPower: "ON" | "OFF"): Promise<void> {
		for (const child of this.children) {
			const dev = DataHandlerDevice.get(child.device_id!) as DeviceRPi
			if (dev.isOnline) await dev.setProjectorPower(newPower)
		}
	}

	// #endregion ============== Getters =================

	// #region ================= Utility =================

	isReadyToPlay(show: Lightshow | Scene): boolean {
		for (const child of this.getChildDevices()) {
			if (!child.isOnline) continue
			if (!child.isReadyToPlay(show)) return false
		}
		return true
	}

	isShowDownloading(show: Lightshow | Scene) {
		for (const child of this.getChildDevices()) {
			if (!child.isOnline) continue
			if (child.isShowDownloading(show)) return true
		}
		return false
	}

	isShowOutdated(show: Lightshow | Scene): boolean {
		for (const child of this.getChildDevices()) {
			if (!child.isOnline) continue
			if (child.isShowOutdated(show, true)) return true
		}
		return false
	}

	// #endregion ============== Utility =================

	isUpdateAvailable(): boolean {
		return false
	}

	update(): void {
		throw new Error("Cannot update a device group")
	}
}
