<script lang="ts">
	import { DataHandlerLightshow, DataHandlerScene, DeviceRPi, Lightshow, Scene } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import SignalStrengthIndicator from "./SignalStrengthIndicator.svelte"
	import ShowDownloads from "./ShowDownloads.svelte"

	const EMPTY_QUEUE_ONLINE = "This device has no scenes currently queued for download."
	const EMPTY_QUEUE_OFFLINE = "This device is currently offline and cannot download any shows."

	const EMPTY_DOWNLOADS_ONLINE = "There are no scenes downloaded to this device."
	const EMPTY_DOWNLOADS_OFFLINE =
		"This device is offline, and the list of downloaded shows is inaccessible."

	let emptyQueueText = EMPTY_QUEUE_OFFLINE
	let emptyDownloadsText = EMPTY_DOWNLOADS_OFFLINE

	export let triggerSave = undefined
	let listenerId

	let device: DeviceRPi

	let signalStrength: number

	let cachedShows: Array<Scene | Lightshow> = []
	let queuedDownloads: Array<Scene | Lightshow> = []

	SelectedDeviceStore.subscribe((dev) => {
		if (!(dev instanceof DeviceRPi) || dev.compareVersion("3.2.0") < 0) {
			console.error(
				"Accessing downloads tab with a device which does not have the download feature."
			)

			signalStrength = 0
			cachedShows = []
			queuedDownloads = []

			return
		}

		initializeDeviceInfo(dev)
	})

	function refreshDeviceInfo(device: DeviceRPi) {
		const getShow = (id: number | string) =>
			DataHandlerScene.get(Number(id)) ?? DataHandlerLightshow.get(Number(id))

		signalStrength = device.eidos?.network?.signal_strength

		cachedShows = Object.keys(device.eidos?.cache?.shows ?? {}).map(getShow) ?? []
		cachedShows = cachedShows.filter((show) => !!show)

		const downloadQueueEidos = device.eidos?.cache?.download_queue ?? []
		if (device.eidos?.cache?.currently_downloading)
			downloadQueueEidos.push(device.eidos?.cache?.currently_downloading)

		queuedDownloads = downloadQueueEidos.map(getShow) ?? []
		queuedDownloads = queuedDownloads.filter((show) => !!show)

		if (device.isOnline) {
			emptyQueueText = EMPTY_QUEUE_ONLINE
			emptyDownloadsText = EMPTY_DOWNLOADS_ONLINE
		} else {
			emptyQueueText = EMPTY_QUEUE_OFFLINE
			emptyDownloadsText = EMPTY_DOWNLOADS_OFFLINE
		}
	}

	function initializeDeviceInfo(dev: DeviceRPi) {
		if (listenerId) device.removeUpdateListener(listenerId)

		device = dev
		refreshDeviceInfo(device)

		listenerId = device.addUpdateListener(refreshDeviceInfo)
	}
</script>

<div class="network-properties" id="network-properties-{device.id}">
	<div class="flex-row">
		<span class="label">Network<br /> Strength:</span>
		<SignalStrengthIndicator strength={signalStrength / 100} />
		<span class="signal-percent">
			{signalStrength ?? 0}%
		</span>
	</div>
	<div class="downloads">
		<ShowDownloads
			heading="Queued Downloads"
			shows={queuedDownloads}
			info="These shows are scheduled to be downloaded."
			emptyText={emptyQueueText}
		/>
		<ShowDownloads
			heading="Downloaded Shows"
			shows={cachedShows}
			info="These shows have been downloaded successfully to your device."
			emptyText={emptyDownloadsText}
		/>
	</div>
</div>

<style>
	.network-properties {
		width: 100%;
		max-width: 100%;
		flex-grow: 1;
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
		/* justify-content: space-between; */
	}

	.flex-row {
		align-items: center;
	}

	.label {
		margin: 0 0.75rem 0 0;
		line-height: 1.25em;
		color: var(--color-text);
	}

	.signal-percent {
		font-family: var(--font-heading);
		color: var(--color-text-light);
		margin-left: 1rem;
		font-size: var(--text-h3);
		line-height: 1em;
	}

	.network-properties :global(.download-list) {
		background-color: var(--color-bg-dark);
		overflow: hidden;
		margin-top: 1rem;
		min-width: 1rem;
		min-height: 1rem;
		padding: 1rem;
		border-radius: var(--br);
	}
</style>
